<template>
  <div>
       <div class="box">
         <el-image class="companylogo"   src="logo.png"  />
         <h2>Login </h2>
                <p>Welcome to TSA - Time Sheet</p>
                <form>
                  <div class="inputBox">
                    <input  type="text" :data-value="user.loginname"  name="loginname"   v-model="user.loginname">
                    <label for="loginname">Login Name</label>
                  </div>
                  <div class="inputBox">
                        <input  type="password" name="password" :data-value="user.password"  v-model="user.password"  @keyup.enter="login">
                        <label for="password">Passward</label>
                      </div>
                  <el-button size="medium" style=" float: right;" type="primary" @click="login">Login </el-button>
                </form>
              </div>    
  </div>
</template>
<script>
import { eventbus } from "../eventbus";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      user: {
        loginname: "",
        password: "",
        subscription: {
          endpoint: "",
          p256dh: "",
          auth: ""
        }
      },
      msg: "",
      error: false,
    };
  },
  methods: {
    login: function () {
      this.user.subscription = this.$store.state.subscription;
      this.$http
        .post("account/login", this.user)
        .then((response) => {
          eventbus.$emit("login", response.data);
        })
        .catch((error) => {
          if(typeof (error.response.data) != "object"){

             ElMessage.error(error.response.data);
          }else{
            this.apierror(error);
          }
          // this.msg = error.response.data
          eventbus.$emit("logout", null);
          this.error = true;
        });
    },
  },
  created() {
    
     this.$nextTick(()=>{
        
       this.user.loginname = " "; 
       this.user.loginname = ""; 
       this.user.password = " "; 
       this.user.password = ""; 
     });
  },
};
</script>
<style >
 /* body {
    margin: 0;
    padding: 0;
    background-size: cover;
    font-family: sans-serif;
  } */
  
  .companylogo{
    width: 52%;
    left: 24%;
  }
    .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25rem;
    padding: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;

  }
  
  .box h2 {
    margin: 0px 0 -0.125rem;
    padding: 0;
    color: #fff;
    text-align: center;
    color: #202124;
    font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
  }

  .box p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .1px;
    /* line-height: 1.5; */
    margin-bottom: 25px;
    text-align: center;
  }
  
  .box .inputBox {
    position: relative;
  }
  
  .box .inputBox input {
    width: 93%;
    padding: 0.625rem 10px;
    font-size: 1rem;
    letter-spacing: 0.062rem;
    margin-bottom: 1.875rem;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 4px;
    
  }
  
  .box .inputBox label {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 0.625rem 0;
    font-size: 1rem;
    color: grey;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .box .inputBox input:focus ~ label,
  .box .inputBox input:valid ~ label,
  .box .inputBox input:not([data-value=""]) ~ label {
    top: -1.125rem;
    left: 10px;
    color: #1a73e8;
    font-size: 0.75rem;
    background-color: white;
    height: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .box .inputBox input:focus {
    outline: none;
    border: 2px solid #1a73e8;
  }
  .box input[type="submit"] {
    border: none;
    outline: none;
    color: #fff;
    background-color: #1a73e8;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    border-radius: 0.312rem;
    font-size: 1rem;
    float: right;
  }
  
  .box input[type="submit"]:hover {
    background-color: #287ae6;
    box-shadow: 0 1px 1px 0 rgba(66,133,244,0.45), 0 1px 3px 1px rgba(66,133,244,0.3);
  }
  
</style>