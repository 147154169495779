<template>
    <div v-show="validuser">
        <el-row>
            <el-col :span="24">
                <!-- <el-header style="width:100%"> -->
                <el-menu class="sidebar-menu heightauto " mode="horizontal" background-color="#333333" text-color="#ffffff"
                    active-text-color="#ffffff">
                    <el-menu-item index="1">
                        <router-link :to="{ name: 'home', path: '/' }">Home</router-link>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <router-link :to="{ name: 'TimeEntry', path: '/timesheet' }">TimeEntry</router-link>
                    </el-menu-item>
                    <el-sub-menu index="3" v-if="canread('Project') || canread('Project Actions')">
                        <template #title>
                            Project
                        </template>
                        <el-menu-item index="3-1" v-if="canread('Project')" class="linecenter">
                            <router-link :to="{ name: 'Project', path: '/project' }">Project</router-link>
                        </el-menu-item>
                        <el-menu-item index="3-2" v-if="canread('Project Actions')" class="linecenter">
                            <router-link :to="{ name: 'Project Actions', path: '/projectaction' }">Project
                                Actions</router-link>
                        </el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="4" v-if="canread('Overhead') || canread('Overhead Actions')">
                        <template #title>
                            Overhead
                        </template>
                        <el-menu-item class="linecenter" index="4-1" v-if="canread('Overhead')">
                            <router-link :to="{ name: 'Overhead', path: '/overhead' }">Overhead</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="4-2" v-if="canread('Overhead Actions')">
                            <router-link :to="{ name: 'Overhead Actions', path: '/overheadaction' }">Overhead
                                Actions</router-link>
                        </el-menu-item>
                    </el-sub-menu>

                    <el-sub-menu index="5" v-if="canread('User Last Entry') ||
                        canread('Project Hours') ||
                        canread('Project Summary') ||
                        canread('Individual Search') ||
                        canread('Multiple Searches') ||
                        canread('Overheads - TSA') ||
                        canread('Overheads - Other') ||
                        canread('Overheads - Full')">
                        <template #title>
                            Reports
                        </template>
                        <el-menu-item class="linecenter" index="5-9" v-if="canread('Outsource Invoice')">
                            <router-link :to="{ name: 'Outsource Invoice', path: '/outsourceinvoice' }">Outsource
                                Invoice</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-1" v-if="canread('User Last Entry')">
                            <router-link :to="{ name: 'User Last Entry', path: '/userlastentry' }">User Last
                                Entry</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-2" v-if="canread('Project Hours')">
                            <router-link :to="{ name: 'Project Hours', path: '/projecthours' }">Project Hours</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-3" v-if="canread('Project Summary')">
                            <router-link :to="{ name: 'Project Summary', path: '/projectsummary' }" class="A">Project
                                Summary</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-4" v-if="canread('Individual Search')">
                            <router-link :to="{ name: 'Individual Search', path: '/reports' }">Individual
                                Search</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-5" v-if="canread('Multiple Searches')">
                            <router-link :to="{ name: 'Multiple Searches', path: '/rmanage' }">Multiple
                                Searches</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-6" v-if="canread('Overheads - TSA')">
                            <router-link :to="{ name: 'Overheads - TSA', path: '/overheadsummary' }">Overheads - TSA</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-7" v-if="canread('Personal Summary')">
                            <router-link :to="{ name: 'Personal Summary', path: '/personalsummary' }">Personal Summary</router-link>
                        </el-menu-item>
                        <el-menu-item class="linecenter" index="5-8" v-if="canread('Overheads - Full')">
                            <router-link :to="{ name: 'Overheads - Full', path: '/roverhead' }">Overheads 
                                Full</router-link>
                        </el-menu-item>
                    </el-sub-menu>

                    <el-sub-menu index="6" v-if="canread('Sales - Quotation Form') ||
                        canread('Sales - Invoicing Form') ||
                        canread('International Payments') ||
                        canread('Operative Cost') ||
                        canread('Fee Table')">
                        <template #title>
                            Data
                        </template>
                        <el-menu-item index="6-1" v-if="canread('Sales - Quotation Form')" class="linecenter">
                            <router-link :to="{ name: 'Sales - Quotation Form', path: '/salesquotation' }" class="A">Sales -
                                Quotation Form</router-link>
                        </el-menu-item>
                        <el-menu-item index="6-2" v-if="canread('Sales - Invoicing Form')" class="linecenter">
                            <router-link :to="{ name: 'Sales - Invoicing Form', path: '/salesinvoice' }" class="A">Sales -
                                Invoicing Form</router-link>
                        </el-menu-item>
                        <el-menu-item index="6-3" v-if="canread('International Payments')" class="linecenter">
                            <router-link :to="{ name: 'International Payments', path: '/internationalpayments' }"
                                class="A">International Payments</router-link>
                        </el-menu-item>
                        <el-menu-item index="6-4" v-if="canread('Operative Cost')" class="linecenter">
                            <router-link :to="{ name: 'Operative Cost', path: '/operativecost' }" class="A">Operative
                                Cost</router-link>
                        </el-menu-item>
                        <el-menu-item index="6-5" v-if="canread('Fee Table')" class="linecenter">
                            <router-link :to="{ name: 'Fee Table', path: '/feerates' }" class="A">Fee Table</router-link>
                        </el-menu-item>
                    </el-sub-menu>

                    <el-sub-menu index="7">
                        <template #title>
                            Admin
                        </template>
                        <el-menu-item index="7-1" v-if="canread('Role')" class="linecenter">
                            <router-link :to="{ name: 'Role', path: '/role' }" class="A">Role</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-2" class="linecenter" v-if="canread('New Role')">
                            <router-link :to="{ name: 'New Role', path: '/newrole' }">New Role</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-3" v-if="canread('Access Permissions')" class="linecenter">
                            <router-link :to="{ name: 'Access Permissions', path: '/userpermission' }" class="A">Access
                                Permissions</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-4" v-if="canread('Access - Sign Off')" class="linecenter">
                            <router-link :to="{ name: 'Access - Sign Off', path: '/accesssignoff' }" class="A">Access - Sign
                                Off</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-5" class="linecenter">
                            <router-link :to="{ name: 'changepassword', path: '/changepassword' }">Change
                                Password</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-6" class="linecenter" v-if="canread('Pages')">
                            <router-link :to="{ name: 'Pages', path: '/pages' }">Pages</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-7" class="linecenter" v-if="canread('Training Popup')">
                            <router-link :to="{ name: 'Training Popup', path: '/trainingpopup' }">Training
                                Popup</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-8" class="linecenter" v-if="canread('Time Tracking Versions')">
                            <router-link :to="{ name: 'Time Tracking Versions', path: '/updateversions' }">Time Tracking Versions</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-9" class="linecenter" v-if="canread('updatedatabasestructure')">
                            <router-link :to="{ name: 'updatedatabasestructure', path: '/updatedatabasestructure' }"
                                class="A">Update Database Structure</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-10" class="linecenter" v-if="canread('errorlog')">
                            <router-link :to="{ name: 'errorlog', path: '/errorlog' }" class="A">Error Log</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-12" class="linecenter" v-if="canread('Direction Library')">
                            <router-link :to="{ name: 'Direction Library', path: '/directionlibrary' }" >Multiple Searches Library</router-link>
                        </el-menu-item>
                        <el-menu-item index="7-11" class="linecenter">
                            <router-link :to="{ name: 'logout', path: '/logout' }">Log out</router-link>
                        </el-menu-item>
                    </el-sub-menu>
                    <el-tooltip content="Click for guidance" placement="top">
                        <b @click="getGuidancedata" class="helpiconstyle">i</b>
                    </el-tooltip>
                </el-menu>
                <!-- </el-header> -->
            </el-col>
            <!--  <el-col :span="3" class="usernamebox">
            <el-dropdown style="z-index:100;float: right " @command="handleCommand">
                     <span class="el-dropdown-link">
                        {{user.firstname}} {{user.lastname}}<i class="el-icon-arrow-down el-icon--right"></i>
                     </span>
                     <template #dropdown>
                       <el-dropdown-menu>
                          <el-dropdown-item command="logout">Log out</el-dropdown-item>
                          <el-dropdown-item command="changepassword">Change Password</el-dropdown-item>
                       </el-dropdown-menu>
                     </template>
                   </el-dropdown>
                </el-col> -->
        </el-row>


        <el-dialog v-model="dialogTableVisible" :close-on-press-escape="false" :close-on-click-modal="false"
            :modalAppendToBody="false" title="List Of users whose time entry is not updated" width="430px">
            <div style="height: 310px; overflow-y: scroll;">
                <el-table :data="griddata" border style="width: 350px; margin-left: 16px; font-size: 14px;">
                    <el-table-column property="firstname" label="Firstname" width="174"></el-table-column>
                    <el-table-column property="lastname" label="Lastname" width="174"></el-table-column>
                </el-table>
            </div>
            <p style="margin-left: 70px; font-size: 16px; font-weight: bold;">If you want to see list of all users, <br>
                then go to... <span @click="$router.push('/userlastentry'); dialogTableVisible = false;"
                    style="font-weight: bold; color: #409eff; text-decoration: underline">User Last Entry</span> </p>

        </el-dialog>
        <customgraphicdialogue :guidancedialogue="guidancedialogue"></customgraphicdialogue>
    </div>
</template>

<script>
import { eventbus } from "../eventbus";
import customgraphicdialogue from "./customgraphicdialogue.vue";
export default {
    name: "NavMenu",
    data() {
        return {
            collapsed: true,
            token: null,
            validuser: false,
            showerror: false,
            errormessage: "",
            errortitle: "",
            user: {},
            ismenu: {},
            griddata: [],
            dialogTableVisible: false,
            guidancedialogue: { visible: false },
        };
    },
    //   watch: {
    //     validuser:function(newval){
    //         if(newval){
    //              this.$router.push({name:'home',path:'/'})
    //         }
    //     }
    //   },
    computed: {
        canread() {
            return (name) => {
                const { id, superadmin, userrole } = this.user || {};
                if (id) {
                    if (superadmin) {
                        return true;
                    }
                    const role = userrole?.rolepermissionpages.find(({ p, rp }) => p.name === name && rp.permission != 0);
                    return !!role;
                }
            }
        }
    },
    methods: {
        getUser() {
            this.$http.post("account/getuserdetails")
                .then(response => {
                    var user = response.data;
                    this.user = user;
                    this.$router.push({ name: 'home', path: '/' })
                    this.$store.state.user = user;
                    eventbus.$emit('setuser', user);
                    this.validuser = true;
                    // this.getdirectionbyuserid(this.user.id);
                    this.$http.defaults.headers.common['Authorization'] = response.headers.authorization;
                })
                .catch(error => {
                    this.apierror(error);
                    if (!this.validuser) {
                        if (this.$route.path != "/login") {
                            this.$router.push({ name: 'login', path: '/login' })
                        }
                    }
                })
        },

        getGuidancedata: function () {
            let myparam = { showguidancedialog: true };
            let setrouteparam = Object.assign({}, this.$route, { params: myparam });
            let setusergotit = {
                currentpage: null,
                currentroute: setrouteparam,
                pagecellname: null,
            }
            eventbus.$emit('userguidegotit', setusergotit);
        },

        handleCommand(command) {
            switch (command) {
                case "logout":
                    this.$router.push({ name: 'logout', path: '/' + command })
                    break;
                default:
                    this.$router.push({ name: command, path: '/' + command })
                    break;
            }
        },
    },

    components: {
        customgraphicdialogue,
    },

    created: function () {
        if (this.$store.state.user.id != undefined) {
            
            this.validuser = true;
            this.user = this.$store.state.user;
            this.$router.push({ name: 'login', path: '/login' })
        }
        else {
            this.validuser = false;
        }
        document.body.style.zoom = "99%";
        eventbus.$on("connectWebSocket",()=>{
            this.$direction.setcomponent(this);
            this.$direction.connectWebSocket();    
        })
        eventbus.$on('setuser', (user) => {
            if (user.id != undefined) {
                this.user = user;
                this.validuser = true;
                if (this.$store.state.pages.length == 0) {
                    this.getPages(); 
                }
                this.getdirectionbyuserid(this.$store.state.user.id);
                this.tsupdatereminder();
            }
        });
        eventbus.$on('login', (token) => {
            this.token = token;
            this.validuser = true;
            localStorage.setItem('token', token);
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            this.$router.push({ name: 'home', path: '/' }) //dashboard
            this.getUser();
            this.getdirectionbyuserid(this.user.id);
        });

        eventbus.$on('logout', (token) => {
            this.$store.state.user = {};
            this.$store.state.isnotificationtime = false;
            this.token = token;
            this.validuser = false;
            this.user = {};
            localStorage.removeItem('token');
            this.$router.push({ name: 'login', path: '/login' }) //home
            delete this.$http.defaults.headers.common['Authorization'];
        });


        eventbus.$on('pagestatus', (roledetail) => {
            let statusmsg = roledetail.p.status == 1 ? 'This page is currently under development...' : 'This page is currently under review...';
            let style = '<style> .el-message-box {width:35%;} </style>';
            this.$alert(statusmsg + style, "! Warning", { dangerouslyUseHTMLString: true });
        });

        // document.addEventListener('DOMContentLoaded', function() {
        // if (!Notification) {
        //     alert('Desktop notifications not available in your browser. Try Chromium.');
        // return;
        // }

        // if (Notification.permission !== 'granted')
        //     Notification.requestPermission();
        // });

    },
};
</script>
<style>
.linecenter {
    line-height: 200%;
}

.usernamebox {
    color: #ffffff;
    background-color: #454545;
}

.el-dropdown-link {
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    color: #ffffff;
}

.el-icon-arrow-down {
    font-size: 14px;
}

.el-menu--horizontal>.el-menu-item {
    height: 50px;
    line-height: 50px;
}

.el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
    height: 50px;
    line-height: 50px;
}

/* .el-menu--horizontal .el-menu .el-menu-item .a{
         color:   #ffffff;
        background-color: #454545;
    } */
/* .el-menu--horizontal .el-menu .el-sub-menu{
        color:   #ffffff;
        background-color: #454545;
    } */
.el-header {
    height: 50px;
    line-height: 50px;
}

/* .slide-enter-active, .slide-leave-active {
        transition: max-height .35s

    } */

/* .slide-enter, .slide-leave-to {
        max-height: 0px;
    } */

/* .slide-enter-to, .slide-leave {
        max-height: 20em;
    } */
/* .el-menu--popup {
         color:   #ffffff;
        background-color: #454545;
    } */
.el-menu-item a {
    display: block;
    height: 100%;
    width: 100%;
    color: #ffffff;
}

/* .el-menu--horizontal .el-menu .el-menu-item{
         color:   #ffffff;
        background-color: #454545;
    } */
.A {
    color: #ffd800 !important;
}

a {
    text-decoration: none;
}</style>